/*https://github.com/manifestinteractive/weather-underground-icons*/

.wu {
   display: inline-block;
   background-repeat: no-repeat;
   padding: 0;
   margin: 0;
}

/*! Setup Default Sizes */
.wu-16 { width: 16px; height: 16px; }
.wu-32 { width: 32px; height: 32px; }
.wu-64 { width: 64px; height: 64px; }
.wu-128 { width: 128px; height: 128px; }
.wu-256 { width: 256px; height: 256px; }

.wu-chanceflurries { background: url(../images/weather-icons/white/chanceflurries.svg); }
.wu-chancerain { background: url(../images/weather-icons/white/chancerain.svg); }
.wu-chancesleet { background: url(../images/weather-icons/white/chancesleet.svg); }
.wu-chancesnow { background: url(../images/weather-icons/white/chancesnow.svg); }
.wu-chancetstorms { background: url(../images/weather-icons/white/chancetstorms.svg); }
.wu-clear { background: url(../images/weather-icons/white/clear.svg); }
.wu-cloudy { background: url(../images/weather-icons/white/cloudy.svg); }
.wu-flurries { background: url(../images/weather-icons/white/flurries.svg); }
.wu-fog { background: url(../images/weather-icons/white/fog.svg); }
.wu-hazy { background: url(../images/weather-icons/white/hazy.svg); }
.wu-mostlycloudy { background: url(../images/weather-icons/white/mostlycloudy.svg); }
.wu-mostlysunny { background: url(../images/weather-icons/white/mostlysunny.svg); }
.wu-partlycloudy { background: url(../images/weather-icons/white/partlycloudy.svg); }
.wu-partlysunny { background: url(../images/weather-icons/white/partlysunny.svg); }
.wu-rain { background: url(../images/weather-icons/white/rain.svg); }
.wu-sleet { background: url(../images/weather-icons/white/sleet.svg); }
.wu-snow { background: url(../images/weather-icons/white/snow.svg); }
.wu-sunny { background: url(../images/weather-icons/white/sunny.svg); }
.wu-tstorms { background: url(../images/weather-icons/white/tstorms.svg); }
.wu-unknown { background: url(../images/weather-icons/white/unknown.svg); }


.wu-nt-chanceflurries { background: url(../images/weather-icons/white/nt_chanceflurries.svg); }
.wu-nt-chancerain { background: url(../images/weather-icons/white/nt_chancerain.svg); }
.wu-nt-chancesleet { background: url(../images/weather-icons/white/nt_chancesleet.svg); }
.wu-nt-chancesnow { background: url(../images/weather-icons/white/nt_chancesnow.svg); }
.wu-nt-chancetstorms { background: url(../images/weather-icons/white/nt_chancetstorms.svg); }
.wu-nt-clear { background: url(../images/weather-icons/white/nt_clear.svg); }
.wu-nt-cloudy { background: url(../images/weather-icons/white/nt_cloudy.svg); }
.wu-nt-flurries { background: url(../images/weather-icons/white/nt_flurries.svg); }
.wu-nt-fog { background: url(../images/weather-icons/white/nt_fog.svg); }
.wu-nt-hazy { background: url(../images/weather-icons/white/nt_hazy.svg); }
.wu-nt-mostlycloudy { background: url(../images/weather-icons/white/nt_mostlycloudy.svg); }
.wu-nt-mostlysunny { background: url(../images/weather-icons/white/nt_mostlysunny.svg); }
.wu-nt-partlycloudy { background: url(../images/weather-icons/white/nt_partlycloudy.svg); }
.wu-nt-partlysunny { background: url(../images/weather-icons/white/nt_partlysunny.svg); }
.wu-nt-rain { background: url(../images/weather-icons/white/nt_rain.svg); }
.wu-nt-sleet { background: url(../images/weather-icons/white/nt_sleet.svg); }
.wu-nt-snow { background: url(../images/weather-icons/white/nt_snow.svg); }
.wu-nt-sunny { background: url(../images/weather-icons/white/nt_sunny.svg); }
.wu-nt-tstorms { background: url(../images/weather-icons/white/nt_tstorms.svg); }
.wu-nt-unknown { background: url(../images/weather-icons/white/nt_unknown.svg); }



.wu-dark-chanceflurries { background: url(../images/weather-icons/black/chanceflurries.svg); }
.wu-dark-chancerain { background: url(../images/weather-icons/black/chancerain.svg); }
.wu-dark-chancesleet { background: url(../images/weather-icons/black/chancesleet.svg); }
.wu-dark-chancesnow { background: url(../images/weather-icons/black/chancesnow.svg); }
.wu-dark-chancetstorms { background: url(../images/weather-icons/black/chancetstorms.svg); }
.wu-dark-clear { background: url(../images/weather-icons/black/clear.svg); }
.wu-dark-cloudy { background: url(../images/weather-icons/black/cloudy.svg); }
.wu-dark-flurries { background: url(../images/weather-icons/black/flurries.svg); }
.wu-dark-fog { background: url(../images/weather-icons/black/fog.svg); }
.wu-dark-hazy { background: url(../images/weather-icons/black/hazy.svg); }
.wu-dark-mostlycloudy { background: url(../images/weather-icons/black/mostlycloudy.svg); }
.wu-dark-mostlysunny { background: url(../images/weather-icons/black/mostlysunny.svg); }
.wu-dark-partlycloudy { background: url(../images/weather-icons/black/partlycloudy.svg); }
.wu-dark-partlysunny { background: url(../images/weather-icons/black/partlysunny.svg); }
.wu-dark-rain { background: url(../images/weather-icons/black/rain.svg); }
.wu-dark-sleet { background: url(../images/weather-icons/black/sleet.svg); }
.wu-dark-snow { background: url(../images/weather-icons/black/snow.svg); }
.wu-dark-sunny { background: url(../images/weather-icons/black/sunny.svg); }
.wu-dark-tstorms { background: url(../images/weather-icons/black/tstorms.svg); }
.wu-dark-unknown { background: url(../images/weather-icons/black/unknown.svg); }
.wu-dark-nt-chanceflurries { background: url(../images/weather-icons/black/nt_chanceflurries.svg); }
.wu-dark-nt-chancerain { background: url(../images/weather-icons/black/nt_chancerain.svg); }
.wu-dark-nt-chancesleet { background: url(../images/weather-icons/black/nt_chancesleet.svg); }
.wu-dark-nt-chancesnow { background: url(../images/weather-icons/black/nt_chancesnow.svg); }
.wu-dark-nt-chancetstorms { background: url(../images/weather-icons/black/nt_chancetstorms.svg); }
.wu-dark-nt-clear { background: url(../images/weather-icons/black/nt_clear.svg); }
.wu-dark-nt-cloudy { background: url(../images/weather-icons/black/nt_cloudy.svg); }
.wu-dark-nt-flurries { background: url(../images/weather-icons/black/nt_flurries.svg); }
.wu-dark-nt-fog { background: url(../images/weather-icons/black/nt_fog.svg); }
.wu-dark-nt-hazy { background: url(../images/weather-icons/black/nt_hazy.svg); }
.wu-dark-nt-mostlycloudy { background: url(../images/weather-icons/black/nt_mostlycloudy.svg); }
.wu-dark-nt-mostlysunny { background: url(../images/weather-icons/black/nt_mostlysunny.svg); }
.wu-dark-nt-partlycloudy { background: url(../images/weather-icons/black/nt_partlycloudy.svg); }
.wu-dark-nt-partlysunny { background: url(../images/weather-icons/black/nt_partlysunny.svg); }
.wu-dark-nt-rain { background: url(../images/weather-icons/black/nt_rain.svg); }
.wu-dark-nt-sleet { background: url(../images/weather-icons/black/nt_sleet.svg); }
.wu-dark-nt-snow { background: url(../images/weather-icons/black/nt_snow.svg); }
.wu-dark-nt-sunny { background: url(../images/weather-icons/black/nt_sunny.svg); }
.wu-dark-nt-tstorms { background: url(../images/weather-icons/black/nt_tstorms.svg); }
.wu-dark-nt-unknown { background: url(../images/weather-icons/black/nt_unknown.svg); }