@import (css) url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic);

// Following variables are used by winphone, mobile and compact themes only even
// thought that is not specified in the names to avoid long variable names.

@pageMenuBottomPadding: 3px;
@pageMenuIconSizeSmall: 38px;
@pageMenuIconSizeBig: 46px;
@pageMenuIconVerticalMarginSmall: 3px;
@pageMenuIconVerticalMarginBig: 5px;

@pageBottomPaddingSmall: 2 * @pageMenuBottomPadding + @pageMenuIconSizeSmall
      + 2 * @pageMenuIconVerticalMarginSmall;
@pageBottomPaddingBig: 2 * @pageMenuBottomPadding + @pageMenuIconSizeBig
      + 2 * @pageMenuIconVerticalMarginBig;


.-theme-material {
  @textColor: rgb(61, 61, 61);
  @primaryColor: rgb(54, 100, 216);
  @secondaryColor: rgb(54,150,40);

  color: @textColor;
  background: rgb(248, 248, 248) !important;

  .header {
    color: #fff;
    background: rgb(54, 100, 216);
    font-weight: lighter;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding-bottom: 10px;
  }

  .group-title {
    color: @textColor;
    font-weight: 400;
  }

  .pages-menu {
    &--item {
      color: rgb(84, 84, 84);
      opacity: 0.8;

      &.-active {
        opacity: 1;
      }
    }
  }

  .item {
    color: #06090a;
    background: rgb(255, 255, 255) !important;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    &-state {
      background: transparent;
      font-weight: lighter;
      color: rgb(94, 94, 94);
      padding: 3px 5px 2px;
    }

    &-entity {
      color: rgb(95, 92, 83);
    }

    &-clickable {
      border-left: 3px solid @primaryColor !important;
      border-top: 3px solid @primaryColor !important;
    }

    &-subtitle {
      font-size: 14px;
      bottom: 21px;
    }

    &-title {
      height: 22px;
      line-height: 22px;
      padding-bottom: 1px;
    }

    &-title, &-subtitle {
      padding-left: 7px;
      font-weight: normal;
    }

    &.-device_tracker {
      background: #222 !important;
    }

    &.-device_tracker,
    &.-camera,
    &.-camera_thumbnail {
      .item-title {
        color: #fff;
      }
      .item-state {
        color: #fff;
      }
    }

    .item-entity--icon {
      color: #6a686f;
    }

    &.-disarmed, &.-off {
      color: #75737a;

      .item-state {
        color: #58585d;
      }

      .item-entity--icon {
        opacity: 0.8;
      }
    }
    &.-armed, &.-on {
      .item-entity--icon {
        color: @secondaryColor;
      }
    }
  }

  .item-list {
    &--name {
      color: @textColor;
      
      .mdi {
        opacity: 0.6;
      }
    }
  }

  .wu {
    opacity: 0.7;
  }

  .item-select {
    background: #fff;
    border: 1px solid #aaa;

    &--option {
      &:active, &.-active {
        background: #eee;

      }
    }
  }

  .media-player--source {
    color: #555;
  }

  input[type=range]::-webkit-slider-runnable-track {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    border: 0px solid #000101;
  }
  input[type=range]::-moz-range-track {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  }
  input[type=range]::-webkit-slider-thumb {
    background: @primaryColor;
    width: 24px;
    height: 14px;
    border-radius: 8px;
  }
  input[type=range]::-moz-range-thumb {
    background: @primaryColor;
    width: 24px;
    height: 14px;
    border-radius: 8px;
  }
  input[type=range]::-ms-thumb {
    background: @primaryColor;
    width: 24px;
    height: 14px;
    border-radius: 8px;
    cursor: pointer;
  }

  .noty {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

.-theme-transparent {
  color: #eee;

  .item {
    color: rgb(245, 239, 238);
    background: rgba(132, 122, 116, 0.1) !important;
    box-shadow: 1px 1px 0 rgba(54, 47, 36, 0.35), -1px -1px 0 rgba(200, 200, 200, 0.1);
    border-radius: 2px;

    &-state {
      background: rgba(0, 0, 0, 0.1);
    }

    &.-device_tracker {
      background: #222 !important;

      .item-slides-container {
        opacity: 0.8;
      }
    }

    &.-device_tracker,
    &.-camera,
    &.-camera_thumbnail {
      .item-title {
        color: #fff;
      }
    }

    &.-armed, &.-on {
      .item-entity--icon, .item-state {
        color: rgb(255, 241, 204);
      }
    }
  }
}

.-theme-win95 {
  color: #eee;
  background: rgb(35, 129, 129) !important;

  .item {
    color: rgb(33, 32, 32);
    background: rgb(200, 202, 203) !important;
    border: 1px solid #777;
    border-top-color: #eeeeee;
    border-left-color: #eee;
    margin: -1px;
    box-shadow: 1px 1px 0 #000, -1px -1px 0 rgba(245, 245, 245, 1);
    border-radius: 1px;

    &-select {
      background: rgb(225, 223, 223);
    }

    &.-device_tracker, &.-camera, &.-camera_thumbnail {
      .item-title {
        color: #fff;
      }
    }
  }
}

.-theme-winphone, .-theme-mobile, .-theme-compact {
  font-size: 14px;

  .item {
    font-size: 13.8px;
    border-radius: 0;

    &-title, &-subtitle {
      padding-left: 5px;
      padding-right: 5px;
      font-weight: normal;
      font-size: 13px;
    }

    &-title {
      height: 20px;
      font-size: 14px;
    }

    &-subtitle {
      bottom: 20px;
    }

    &-state {
      padding: 1px 4px 1px;
      right: 4px;
    }

    &-entity {
      &.-select .item-entity--value {
        font-size: 21px * 0.8;
      }
    }
  }

  .pages-menu {
    &.-left {
      width: 60px;
    }

    &.-bottom {
      padding: @pageMenuBottomPadding;
      background: rgba(32, 35, 37, 0.4);
      bottom: 0;

      .pages-menu--scroll-indicator {
        display: none;
      }
    }

    .pages-menu {
      &--item {
        font-size: 24px;
        line-height: 36px;
        width: @pageMenuIconSizeSmall;
        height: @pageMenuIconSizeSmall;
        margin: @pageMenuIconVerticalMarginSmall 5px;
        opacity: 0.6;

        &.-active {
          opacity: 1;
          background: rgba(32, 35, 37, 0.4);
        }
      }
    }

    @media (min-width: 500px) {
      padding: 6px;

      .pages-menu {
        &--item {
          font-size: 30px;
          line-height: 44px;
          width: @pageMenuIconSizeBig;
          height: @pageMenuIconSizeBig;
          margin: @pageMenuIconVerticalMarginBig 5px;
        }
      }
    }
  }

  &.-menu-left {
    .page-align {
      margin-left: 70px;
    }

    &.-groups-align-vertically {
      .page {
        padding-left: 70px;
      }
    }
  }

  &.-menu-bottom {
    .page {
      padding-bottom: @pageBottomPaddingSmall;
    }

    @media (min-width: 500px) {
      .page {
        padding-bottom: @pageBottomPaddingBig;
      }
    }
  }
}

.-theme-mobile, .-theme-winphone {
  .group-title {
    height: 40px;
    top: -40px;
    line-height: 40px;
  }

  .page {
    white-space: normal;

    .group {
      display: block;
      white-space: nowrap;
    }
  }

  .page-align {
    height: 0;
    display: none;
  }

  .weather-list {
    &-icon, &-icon-image {
      > div {
        width: 28px;
        height: 28px;
      }
    }
  }

  .item {
    &.-th-transparent {
      background-color: rgba(87, 91, 131, 0.47);
    }
  }

  &.-groups-align-vertically .page {
    padding-left: 0;
  }
}

.-theme-winphone {
  @winphonePageMenuIconBorderSize: 3px;

  font-size: 13.5px;

  .item {
    color: rgb(245, 239, 238);
    background: rgba(34, 102, 168, 0.3) !important;

    &:active {
      background: rgba(34, 102, 168, 0.4) !important;
    }

    &-state {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .pages-menu.-bottom {
    background: rgb(40, 40, 40);

    .pages-menu {
      &--item {
        border-radius: 100%;
        border: @winphonePageMenuIconBorderSize solid rgba(255, 255, 255, .2);
      }
    }
  }

  &.-menu-bottom {
    .page {
      padding-bottom: @pageBottomPaddingSmall + 2 * @winphonePageMenuIconBorderSize;
    }

    @media (min-width: 500px) {
      .page {
        padding-bottom: @pageBottomPaddingBig + 2 * @winphonePageMenuIconBorderSize;
      }
    }
  }
}

.-theme-homekit {
  @pageMenuBottomTopBorderWidth: 1px;
  @pageMenuIconSize: 70px;
  @pageMenuIconVerticalMargin: 5px;
  @pageBottomPadding: @pageMenuBottomTopBorderWidth
         + @pageMenuIconSize + 2 * @pageMenuIconVerticalMargin;;

  color: #fff;
  background: #eee !important;

  @TILE_COLOR_ON: #fff;
  @TILE_BACKGROUND_ON: rgb(255, 255, 255);
  @TILE_COLOR_OFF: rgb(84, 77, 75);
  @TILE_BACKGROUND_OFF: linear-gradient(to right bottom, rgba(253, 251, 249, 0.95), rgba(245, 245, 245, 0.8));

  .item {
    color: #363636;
    border-radius: 8px;
    background: @TILE_BACKGROUND_ON;

    &-state {
      background: transparent;
      font-weight: 500;
      color: rgb(104, 104, 104);
      padding: 3px 5px 2px;
    }

    &-entity {
      color: rgb(98, 95, 86);
    }

    &-clickable {
      border: 0 !important;
      height: 7px !important;
      width: 7px !important;
      left: 7px !important;
      top: 7px !important;
      border-radius: 7px !important;
      background: rgb(255, 162, 0);
    }

    &-subtitle {
      font-size: 14px;
      bottom: 21px;
    }

    &-title {
      height: 22px;
      line-height: 22px;
      padding-bottom: 1px;
    }

    &-title, &-subtitle {
      padding-left: 7px;
    }

    &.-device_tracker {
      background: #222 !important;
    }

    &.-device_tracker,
    &.-camera,
    &.-camera_thumbnail {
      .item-title, .item-state {
        color: @TILE_COLOR_ON;
      }
    }

    .item-entity--icon {
      color: rgb(144, 142, 150);
    }

    &.-on, &.-armed, &.-locked {
      background: @TILE_BACKGROUND_ON;
    }

    &.-off, &.-disarmed, &.-arming, &.-loading {
      color: @TILE_COLOR_OFF;

      .item-state {
        color: @TILE_COLOR_OFF;
      }

      .item-entity--icon {
        color: rgba(159, 150, 140, 0.8);
      }

      background: @TILE_BACKGROUND_OFF;
    }
  }

  .group-title {
    height: 40px;
    top: -40px;
    line-height: 40px;
    font-weight: bold;
    opacity: 1;
    font-size: 17px;
  }

  .weather-list table tr:nth-child(2n) {
    background: rgba(230, 202, 182, 0.1);
  }

  .pages-menu.-bottom {
    background: #fafafa;
    border-top: @pageMenuBottomTopBorderWidth solid #d2d2d2;
    padding: 0;

    .pages-menu--scroll-indicator {
      display: none;
    }

    .pages-menu {
      &--item {
        color: #777;
        font-size: 26px;
        margin: 5px 8px;

        &.-active {
          background: none;
          color: rgb(142, 132, 124);
        }
      }
    }
  }

  .wu {
    opacity: 0.7;
  }

  .item-select {
    background: #fff;
    border: 1px solid #aaa;

    &--option {
      &:active, &.-active {
        background: #eee;

      }
    }
  }

  .media-player--source {
    color: #555;
  }

  input[type=range]::-webkit-slider-runnable-track {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    border: 0px solid #000101;
  }
  input[type=range]::-moz-range-track {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  }
  input[type=range]::-webkit-slider-thumb {
    border-radius: 10px;
  }
  input[type=range]::-moz-range-thumb {
    border-radius: 10px;
  }

  &.-menu-bottom {
    .page {
      padding-bottom: @pageBottomPadding;
    }
  }

  &.-theme-mobile {
    .item {
      &-state {
        padding: 4px 5px 2px !important;
      }
    }
  }
}


.-theme-fresh-air {
   background: rgb(21, 89, 208);
   color: #fff;
   font-family: 'Roboto', sans-serif;

   .header {
      clock {
         margin-bottom: 5px;
      }
   }

   .group-title {
      opacity: 1;
      font-weight: 500;
   }

   .item {
      color: #fff;
      border: 2px solid rgba(226, 237, 255, 0.6);//rgba(255,255,255,0.99);
      background: rgba(132, 122, 116, 0) !important;
      box-shadow: none;
      border-radius: 1px;

      &-state {
         background: rgba(0, 0, 0, 0.1);
      }

      &.-device_tracker {
         background: #222 !important;
      }

      &.-device_tracker,
      &.-camera,
      &.-camera_thumbnail {
         .item-title {
            color: #fff;
         }
      }

      &.-armed, &.-on {
         border-color: rgb(255, 202, 171);
         .item-entity--icon, .item-state {
            color: rgb(253, 231, 228);
         }
      }
   }
}

.-theme-white-paper {
   background: #fff;
   color: #444;
   font-family: 'Roboto', sans-serif;

   .header {
      color: #444;

      clock {
         margin-bottom: 5px;
      }
   }

   .group-title {
      opacity: 1;
      font-weight: 500;
   }

   .item-select {
      color: #fff;
   }

   .pages-menu {
      &--item {
         color: #444;
      }
   }

   .group-title {
      color: #444;
   }

   input[type=range]::-webkit-slider-runnable-track {
      background: #ddd;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
      border: 0px solid #000101;
   }
   input[type=range]::-moz-range-track {
      background: #ddd;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
   }
   input[type=range]::-webkit-slider-thumb {
      background: #555;
      border-radius: 10px;
   }
   input[type=range]::-moz-range-thumb {
      background: #555;
      border-radius: 10px;
   }
   .item {
      color: #444;
      border: 2px solid rgb(133, 133, 133);
      background: rgba(132, 122, 116, 0) !important;
      box-shadow: none;
      border-radius: 2px;

      .item-entity--icon {
         color: #555;
      }

      &-state {
         background: transparent;
         border-bottom: 1px solid rgba(129, 124, 124, 0.5);
         padding: 2px 2px 0;
         color: #444;
         opacity: 1;
      }

      &.-device_tracker {
         background: #222 !important;
      }

      &.-device_tracker,
      &.-camera,
      &.-camera_thumbnail {
         .item-title {
            color: #fff;
         }
      }

      &.-armed, &.-on {
         border-color: rgb(255, 134, 94);
         .item-entity--icon, .item-state {
            //color: rgb(228, 102, 43);
            //border-color: rgba(194, 141, 130, 0.48);
         }
         .item-state {
            color: rgb(228, 102, 43);
            border-color: rgba(194, 141, 130, 0.48);
         }
      }
   }

   .pages-menu.-bottom {
      background: rgba(255,255,255,.92);
      border-top: 2px solid rgb(163, 163, 163);
      padding: 0;

      .pages-menu--scroll-indicator {
         display: none;
      }

      .pages-menu {
         &--item {
            color: #777;
            font-size: 26px;
            margin: 5px 8px;

            &.-active {
               background: none;
               color: #444;
            }
         }
      }
   }

}
